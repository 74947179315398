/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  campaignApi,
  ShareType,
  SystemRole,
  useGetCampaignQuery,
  User,
  useUpdateMemberClaimedMutation,
} from '@app/services';
import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Center,
  Flex,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {
  ContractActions,
  CountDown,
  FormatToken,
  Loading,
  NotchedBox,
  NotchedButton,
  PrizeCard,
  ShareButton,
  Wrapper,
  WrapperPattern,
} from '@components';
import { BSC_SCAN } from '@constants';
import { MembersTableContainer } from '@containers';
import { Web3Provider } from '@ethersproject/providers';
import {
  getCampaignById,
  selectCurrentUser,
  selectTicketValue,
} from '@features';
import {
  useAppDispatch,
  useAppSelector,
  useAuth,
  useTokenIdOfAccount,
} from '@hooks';
import {
  botSendEvent,
  botSendMessage,
  botShow,
  BotSupportType,
  claimLixi,
} from '@utils';
import { useWeb3React } from '@web3-react/core';
import {
  addToken,
  claimLocalJackpot,
  fetchingTokenInfo,
  MetamaskToken,
} from '@web3react';
import React, { useEffect, useRef, useState } from 'react';
import Confetti from 'react-confetti';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useWindowSize from 'react-use/lib/useWindowSize';
import barLine from '../assets/barLine.png';
import successIcon from '../assets/successIcon.svg';
import { Lottery } from '../components/Lottery';

export const DetailCampaign: React.FC = () => {
  const { signIn } = useAuth();
  const [isOwner, setIsOwner] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isClaimSuccess, setIsClaimSuccess] = useState(false);
  const [isWheelSuccess, setIsWheelSuccess] = useState(false);
  const [wheelWinner, setWheelWinner] = useState('');

  const { campaignId } = useParams();
  const [currentUserStatus, setCurrentUserStatus] = useState('');
  const [isJoinLoading, setIsJoinLoading] = useState(false);

  const { library, account } = useWeb3React<Web3Provider>();
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [extraToken, setExtraToken] = useState<MetamaskToken | undefined>();

  const displayWinningTicketRef = useRef<boolean>(false);

  const fallbackImg =
    'https://nft4charity-assets.s3.us-east-2.amazonaws.com/merge+image/fallback.png';

  const dispatch = useAppDispatch();

  if (!campaignId) {
    return null;
  }

  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'bottom',
  });

  const requestJoinCampaign =
    campaignApi.endpoints.requestJoinCampaign.initiate;

  const [updateMemberClaimed] = useUpdateMemberClaimedMutation();

  const { refetch, isLoading } = useGetCampaignQuery(campaignId);
  const campaign = useAppSelector(state => getCampaignById(state, campaignId));
  const winnerAddress =
    campaign && campaign.winnerAddress ? campaign.winnerAddress : '';
  const isRequireUserInfo =
    campaign && campaign.isRequireUserInfo ? campaign.isRequireUserInfo : false;

  const currentUserTokenIds = useSelector(selectTicketValue) ?? [];
  const currentUserTokenId = useTokenIdOfAccount(
    campaign?.groupId,
    account,
    currentUserTokenIds,
  );

  useEffect(() => {
    (async () => {
      if (!campaign?.currency || !library) {
        return;
      }
      if (!campaign.extraToken) {
        const metamaskToken = await fetchingTokenInfo(campaign?.currency);
        setExtraToken(metamaskToken);
      }
    })();
  }, [campaign, library]);

  const user = useAppSelector(selectCurrentUser);
  useEffect(() => {
    // MARK:- show Bot when Screen is on
    botShow();
    (window as any).addEventListener('message', event => {
      if (event.data.name === 'webchatReady') {
        botSendEvent(BotSupportType.UPDATE_CONTEXT, {
          // MARK:- update user's current
          context: 'detailCampaign',
        });
        if (!user?.walletAddress) {
          // MARK:- show guideline for strangers
          botSendMessage('Nhận lì xì như thế nào?');
        }
      }
    });
  }, []);

  useEffect(() => {
    if (campaignId) {
      refetch();
    }
  }, [campaignId]);

  useEffect(() => {
    if (user && campaign) {
      if (user.role && user.role.toLowerCase() === SystemRole.ADMIN) {
        setIsOwner(true);
        setIsAdmin(true);
      }
      if (user._id === campaign.creator?._id) {
        setIsOwner(true);
      }
    }
  }, [user, campaign]);

  useEffect(() => {
    if (user) {
      // MARK:- send user info to Bot
      (window as any).addEventListener('message', event => {
        if (event.data.name === 'webchatReady') {
          botSendEvent(BotSupportType.UPDATE_USER_INFO, {
            fullName: user.fullName,
            email: user.email,
            walletAddress: user.walletAddress,
          });
        }
      });
    }
  }, [user]);

  useEffect(() => {
    if (user && campaign) {
      const userStatus = campaign.members?.filter(mb => {
        return mb.userId.toLowerCase() === user._id;
      });
      if (userStatus && userStatus.length > 0) {
        setCurrentUserStatus(userStatus[0].status.toString());
        (window as any).addEventListener('message', event => {
          if (event.data.name === 'webchatReady') {
            botSendEvent(BotSupportType.UPDATE_USER_STATUS, {
              claimStatus: userStatus[0].status.toString(),
            });
          }
        });
      }
    }
  }, [user, campaign]);

  useEffect(() => {
    if (wheelWinner) {
      setIsWheelSuccess(true);
    }
  }, [wheelWinner]);

  const isUserEnoughInfo = (user: User | null) =>
    !!user && !!user.fullName && !!user.email && !!user.isEmailVerified;

  const actionCurrentUser = () => {
    if (isOwner) {
      return null;
    }

    if (!user) {
      return (
        <NotchedButton
          onClick={() => {
            signIn();
          }}
          width="100%"
          bgColor="primary"
          color="white"
          p={6}
          fontSize={{ base: '12px', xl: 'md' }}
        >
          Đăng nhập để nhận lì xì
        </NotchedButton>
      );
    }

    if (isRequireUserInfo && !isUserEnoughInfo(user)) {
      return (
        <VStack spacing={5}>
          <Alert status="warning">
            <AlertIcon />
            <Box>
              Bạn cần cập nhật thông tin
              <Text
                display={!user?.fullName ? 'inline' : 'none'}
                fontWeight="700"
              >
                {' '}
                tên đầy đủ
              </Text>
              <Text display={!user?.email ? 'inline' : 'none'} fontWeight="700">
                {!user?.fullName ? ', ' : ' '} email
              </Text>{' '}
              <Text
                display={!user?.isEmailVerified ? 'inline' : 'none'}
                fontWeight="700"
              >
                {!user?.fullName || !user?.email ? ' và ' : ' '} xác thực email
              </Text>{' '}
              để nhận lì xì.
            </Box>
          </Alert>
          <NotchedButton
            width="100%"
            bgColor="primary"
            color="white"
            p={6}
            fontSize={{ base: '12px', xl: 'md' }}
          >
            <RouterLink to={`/profile`} state={{ from: `/bao/${campaignId}` }}>
              Cập nhật thông tin
            </RouterLink>
          </NotchedButton>
        </VStack>
      );
    }

    if (currentUserStatus === '')
      return (
        <NotchedButton
          isLoading={isJoinLoading}
          onClick={() => {
            if (isRequireUserInfo && !isUserEnoughInfo(user)) {
              toast({
                title: 'Success',
                description:
                  'Bạn cần cập nhập thông tin để chủ bao lì xì xác nhận',
                status: 'info',
              });
              return;
            }

            const result = dispatch(requestJoinCampaign(campaignId));
            result.then(response => {
              const { error, isLoading } = response;
              setIsJoinLoading(isLoading);

              if (!error)
                return toast({
                  title: 'Success',
                  description: 'Bạn đã gửi yêu cầu thành công.',
                  status: 'success',
                });

              toast({
                title: 'Error',
                description: 'Gửi yêu cầu nhận lì xì thất bại.',
                status: 'error',
              });
            });
          }}
          width="100%"
          bgColor="primary"
          color="white"
          p={6}
          fontSize={{ base: '12px', xl: 'md' }}
        >
          Gửi yêu cầu nhận lì xì
        </NotchedButton>
      );
    else {
      if (currentUserStatus === 'PENDING') {
        return (
          <Box bgColor="yellowBg" p={5} borderRadius={10}>
            <Text fontWeight="bold">
              Bạn đã gửi yêu cầu và đang chờ chủ bao lì xì duyệt
            </Text>
          </Box>
        );
      } else if (currentUserStatus === 'APPROVED') {
        return (
          <NotchedButton
            onClick={async () => {
              console.log('Nhận lì xì');
              try {
                const txHash = await claimLixi(
                  campaign?.groupId,
                  library?.getSigner(0),
                );

                await updateMemberClaimed({
                  campaignId,
                  userId: user._id?.toString() as string,
                }).unwrap();
                console.log(txHash);
                setIsClaimSuccess(true);
                setCurrentUserStatus('CLAIMED');
              } catch (err) {
                console.log(err);
                toast({
                  title: 'Thông báo lỗi',
                  description:
                    'Bạn đã chưa được duyệt hoặc bạn đã nhận được lì xì này.',
                  status: 'error',
                });
              }
            }}
            width="100%"
            bgColor="primary"
            color="white"
            p={6}
          >
            Nhận lì xì
          </NotchedButton>
        );
      } else if (currentUserStatus === 'DECLINED') {
        return (
          <Box bgColor="yellowBg" p={5} borderRadius={10}>
            <Text fontWeight="bold">Bạn đã bị từ chối nhận lì xì này</Text>
          </Box>
        );
      } else if (currentUserStatus === 'CLAIMED') {
        return (
          <Flex direction="column">
            <Box bgColor="yellowBg" p={5} borderRadius={10}>
              <Text fontWeight="bold">Bạn đã nhận lì xì này</Text>
            </Box>
          </Flex>
        );
      }
    }
  };

  const congratulationsClaimed = () => {
    return (
      <Box>
        <Modal
          isOpen={isClaimSuccess}
          onClose={() => {
            setIsClaimSuccess(false);
          }}
        >
          <ModalOverlay />
          <ModalContent py={10}>
            <ModalBody>
              <Confetti height={windowHeight} width={windowWidth} />
              <Flex flexDirection="column" gap={10}>
                <Center>
                  <Image src={successIcon} w={20} h={20} />
                </Center>
                <Text>Lì xì đã được chuyển đến ví của bạn</Text>
                <NotchedButton
                  bgColor="primary"
                  color="white"
                  onClick={() => {
                    setIsClaimSuccess(false);
                  }}
                >
                  Hoàn thành
                </NotchedButton>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    );
  };

  const congratulationsWheel = () => {
    return (
      <Modal
        isOpen={isWheelSuccess}
        onClose={() => {
          displayWinningTicketRef.current = true;
          setIsWheelSuccess(false);
        }}
      >
        <ModalOverlay />
        <ModalContent py={10}>
          <ModalBody>
            <Confetti height={windowHeight} width={windowWidth} />
            <Flex flexDirection="column" gap={10}>
              <Center>
                <Image src={successIcon} w={20} h={20} />
              </Center>
              <Text>
                Xin chúc mừng địa chỉ ví {winnerAddress} đã chiến thắng
              </Text>
              <NotchedButton
                bgColor="primary"
                color="white"
                onClick={() => {
                  setIsWheelSuccess(false);
                  displayWinningTicketRef.current = true;
                }}
              >
                Đóng
              </NotchedButton>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  const handleAddToken = async (tokenAddress?: string) => {
    if (!tokenAddress || !library) return;

    const metamaskToken = await fetchingTokenInfo(tokenAddress);
    const isAdded = await addToken(metamaskToken);
    !isAdded
      ? toast({
          title: 'Error',
          description: 'Thêm token thất bại',
          status: 'error',
          position: 'bottom',
        })
      : toast({
          title: 'Info',
          description: 'Chọn add token và kiểm tra lại ví Metamask của bạn nhé',
          status: 'info',
          position: 'bottom',
        });
  };

  const handleClaimJackpot = async (ticketId?: number) => {
    if (ticketId == undefined) return;

    if (!library) {
      return toast({
        title: 'Thông báo lỗi',
        description: 'Bạn cần kết nối ví',
        status: 'error',
      });
    }

    try {
      const txHash = await claimLocalJackpot(ticketId, library.getSigner(0));
      toast({
        title: 'Thông báo',
        description: (
          <Flex flexDirection="column" alignItems="center">
            <Text>Bạn đã nhận thành công</Text>

            <Link href={`${BSC_SCAN}/tx/${txHash.hash}`} isExternal>
              <Text color="primary">Xem chi tiết</Text>
            </Link>
          </Flex>
        ),
        status: 'success',
      });
    } catch (error) {
      console.log(error);
      toast({
        title: 'Thông báo lỗi',
        description:
          'Bạn không sở hữu vé chiến thắng hoặc phần thưởng đã được nhận',
        status: 'error',
      });
    }
  };

  return (
    <>
      {isClaimSuccess ? congratulationsClaimed() : null}
      {isLoading ? (
        <Loading />
      ) : (
        <Flex
          w="full"
          flexDirection="column"
          paddingTop={{ base: '4.5rem', md: '8rem' }}
        >
          <WrapperPattern>
            <Flex
              width="100%"
              justifyContent="center"
              alignItems="center"
              mb={{ base: '2rem', md: '2.4rem' }}
            >
              <Wrapper
                flexDirection={{ base: 'column', md: 'row' }}
                width="100%"
                m={{ base: '-0.5rem', md: '0rem' }}
                maxWidth="64rem"
              >
                <VStack
                  p={{ base: '0.5rem', md: '2rem' }}
                  width={{ base: '100%', md: '50%' }}
                >
                  <Box
                    width="100%"
                    height="0px"
                    pb="80%"
                    pos="relative"
                    mb="1rem"
                  >
                    <Image
                      src={
                        campaign?.mergeImageUrl !== null
                          ? campaign?.mergeImageUrl
                          : fallbackImg
                      }
                      pos="absolute"
                      top="0"
                      right="0"
                      left="0"
                      bottom="0"
                      width="100%"
                      height="100%"
                      objectFit="contain"
                    />
                  </Box>
                  <Text color="white" fontSize={16} fontWeight="bold">
                    Thời gian còn lại
                  </Text>
                  <CountDown endTime={campaign?.endTime} />
                </VStack>
                <VStack
                  p={{ base: '0.5rem', md: '2rem' }}
                  align={{ base: 'center', md: 'flex-start' }}
                  width={{ base: '100%', md: '50%' }}
                >
                  <Text
                    fontSize="7xl"
                    fontFamily="headingFont"
                    fontWeight="bold"
                    color="white"
                    lineHeight={1}
                    mt={{ base: '2rem', md: '0' }}
                  >
                    {campaign?.name}
                  </Text>
                  <Image
                    src={barLine}
                    display={{ base: 'none', md: 'inline-block' }}
                  />
                  <Text
                    color="secondary"
                    textAlign={{ base: 'center', md: 'left' }}
                    maxWidth="40rem"
                  >
                    {campaign?.description}
                  </Text>
                  <VStack align={{ base: 'center', md: 'flex-start' }} py={6}>
                    <Text color="white">Giá trị bao lì xì</Text>
                    <FormatToken
                      token={
                        campaign?.extraToken ? campaign.extraToken : extraToken
                      }
                      amount={campaign?.amountOnItem || NaN}
                      currency={campaign?.currency}
                    />
                    <ContractActions
                      extraTokenAddress={
                        campaign?.extraToken
                          ? campaign.extraToken.address
                          : extraToken?.address
                      }
                      handleAddToken={() => {
                        handleAddToken(
                          campaign?.extraToken
                            ? campaign.extraToken.address
                            : extraToken?.address,
                        );
                      }}
                      tokenId={currentUserTokenId}
                    />
                  </VStack>
                  <NotchedBox
                    bgColor="white"
                    width={{ base: '100%', xl: '500px' }}
                    padding={{ base: '4', xl: '10' }}
                    paddingBottom={{ base: '4', xl: '10' }}
                  >
                    <Flex
                      marginTop={{ base: '20px', xl: '0' }}
                      flexDirection={'row'}
                      alignItems="center"
                      justifyContent="space-between"
                      pb={5}
                      borderBottom="1px solid #102536"
                    >
                      <Flex align="flex-start" alignItems="center">
                        <Avatar
                          src={campaign?.creator?.avatar}
                          width={16}
                          height={16}
                        />
                        <Flex flexDirection="column" p={2}>
                          <Text>Người lì xì</Text>
                          <Text fontWeight="bold">
                            {campaign?.creator?.fullName}
                          </Text>
                        </Flex>
                      </Flex>
                      <ShareButton
                        campaignId={campaign?._id || '000'}
                        isOwner={isOwner}
                        shareId={campaign?.shareId}
                        currentStatus={
                          campaign?.shareType === ShareType.AUTO_APPROVE
                            ? 'auto'
                            : 'manual'
                        }
                      />
                    </Flex>

                    {!isOwner && (
                      <Box>
                        <Flex justifyContent="center" p={5}>
                          {actionCurrentUser()}
                        </Flex>
                      </Box>
                    )}
                    <Flex
                      py={{ base: '0', md: 10 }}
                      marginBottom={{ base: '10px', xl: '0' }}
                      marginLeft={{ base: '20px', xl: '0' }}
                      flexDirection={{ base: 'column', md: 'row' }}
                      align={{ base: 'center', md: 'flex-start' }}
                    >
                      <Text flex={'0 0 auto'}>Chỉ còn:&nbsp;</Text>
                      <Text fontWeight="bold">{`${campaign?.remainItems}/${campaign?.numberItem} bao lì xì`}</Text>
                    </Flex>
                  </NotchedBox>
                </VStack>
              </Wrapper>
            </Flex>
          </WrapperPattern>
          <PrizeCard />
          <Flex width="100%" bgColor="white">
            <Wrapper
              width="100%"
              flexDirection="column"
              paddingX={{ base: '10px', md: '5rem' }}
            >
              <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                alignItems="center"
                py={10}
                alignContent="space-between"
                width="100%"
              >
                <Text
                  fontSize={36}
                  fontWeight="bold"
                  textAlign={{ base: 'center', md: 'left' }}
                >
                  Danh sách đăng ký
                </Text>
                <Spacer />
                {!isOwner && (
                  <Flex
                    alignItems="center"
                    flexDirection={{ base: 'column', sm: 'row' }}
                  >
                    <Text mr={2}>Bạn là chủ gói lì xì này?</Text>
                    <NotchedButton
                      marginTop={{ base: '10px', sm: '0px' }}
                      variant="outline"
                      color="primary"
                      borderColor="primary"
                      onClick={() => {
                        // activate(connectorsByName.Injected);
                        signIn();
                      }}
                    >
                      Đăng nhập
                    </NotchedButton>
                  </Flex>
                )}
              </Flex>
              <MembersTableContainer
                isOwner={isOwner}
                isAdmin={isAdmin}
                campaignId={campaignId}
                signer={library?.getSigner(0)}
              />
            </Wrapper>
          </Flex>
          <Lottery
            campaign={campaign}
            setWheelWinner={setWheelWinner}
            winnerAddress={winnerAddress}
            claimJackpot={handleClaimJackpot}
            displayWinningTicket={displayWinningTicketRef.current}
          />
          {isWheelSuccess ? congratulationsWheel() : null}
        </Flex>
      )}
    </>
  );
};

export default DetailCampaign;
