import {
  Box,
  Center,
  Flex,
  Image,
  Switch,
  Text,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import {
  Campaign,
  ShareType,
  useUpdateRequireUserInfoMutation,
} from '@services';
import { formatDistanceToNowVi } from '@utils';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FormatToken, ShareButton, WrapTag } from '..';
import clockIcon from '../../assets/clockIcon.svg';
import { NotchedButton } from '../NotchedButton';

export interface CampaignItemProps {
  campaign: Campaign;
}

export const CampaignItem: React.FC<CampaignItemProps> = ({ campaign }) => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { paymentStatus } = campaign;
  const getNftImage = () => {
    if (campaign.mergeImageUrl) {
      return campaign.mergeImageUrl;
    }

    return campaign.template ? campaign.template.url : '';
  };

  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'bottom',
  });
  const isRequireUserInfo =
    campaign && campaign.isRequireUserInfo ? campaign.isRequireUserInfo : false;
  const campaignId = campaign && campaign._id ? campaign._id : '';
  const [updateRequireUserInfo] = useUpdateRequireUserInfoMutation();
  const handleUpdateRequireUserInfo = async () => {
    try {
      await updateRequireUserInfo({
        campaignId,
        isRequire: !isRequireUserInfo,
      }).unwrap();
    } catch (error) {
      console.log(error);
      toast({
        title: 'Error',
        description: 'Oops, có gì đó hoạt động chưa đúng rồi',
        status: 'error',
      });
    }
  };

  return (
    <Box
      width={{ base: '100%', sm: '50%', lg: 'calc(100%/3)' }}
      p={{ base: '0.5rem', lg: '1rem' }}
    >
      <Flex
        bgColor="white"
        borderRadius={4}
        flexDirection="column"
        height="100%"
      >
        <Box bgColor="yellowBg" pos="relative" w="100%" h="0px" pb="60%">
          <Image
            w="100%"
            h="100%"
            objectFit="contain"
            pos="absolute"
            src={getNftImage()}
          />
          <Flex w="full" pos="absolute" top="1rem">
            <Flex w="full">
              <Wrap w="100%" justify="space-between">
                <WrapItem pos="absolute" left="1rem">
                  <Center>
                    <Flex
                      alignItems="center"
                      bgColor="dark"
                      px={'12px'}
                      py={'6px'}
                      borderRadius={4}
                      gap={'6px'}
                    >
                      <Image src={clockIcon} w={'1rem'} h={'1rem'} />
                      <Text
                        color="white"
                        fontSize="14px"
                        letterSpacing="-0.07em"
                      >
                        {campaign.endTime
                          ? formatDistanceToNowVi(campaign.endTime)
                          : 'Không xác định'}
                      </Text>
                    </Flex>
                  </Center>
                </WrapItem>
                <WrapItem pos="absolute" right="1rem">
                  <ShareButton
                    campaignId={campaign._id}
                    isOwner={true}
                    shareId={campaign.shareId}
                    currentStatus={
                      campaign.shareType === ShareType.AUTO_APPROVE
                        ? 'auto'
                        : 'manual'
                    }
                  />
                </WrapItem>
              </Wrap>
            </Flex>
          </Flex>
        </Box>
        <Flex
          p={'1rem'}
          flexDirection="column"
          h="100%"
          justifyContent="space-between"
        >
          <Flex flexDirection="column">
            <Box pb={5}>
              <Wrap>
                <WrapTag>
                  <FormatToken
                    iconH={4}
                    iconW={4}
                    color="darkBg"
                    fontSize={14}
                    fontWeight={400}
                    token={campaign.extraToken}
                    amount={campaign.amountOnItem}
                  />
                </WrapTag>

                <WrapTag>
                  <Text
                    fontSize={14}
                    fontWeight={400}
                  >{`Còn ${campaign.remainItems} lì xì`}</Text>
                </WrapTag>
              </Wrap>
            </Box>
            <Box pb={'.5rem'}>
              <Link to={`/bao/${campaign._id}`}>
                <Text
                  fontSize={24}
                  fontWeight={700}
                  lineHeight="1.21"
                  letterSpacing="-0.07em"
                >
                  {campaign.name}
                </Text>
              </Link>
            </Box>
            <Box pb={'2rem'}>
              <Text color="#777E90" lineHeight="1.625" letterSpacing="-0.07em">
                {campaign.description}
              </Text>
            </Box>
          </Flex>
          <Flex justifyContent="space-between" marginBottom="15px">
            <Text>Yêu cầu thông tin người dùng</Text>
            <Switch
              size="lg"
              isChecked={isRequireUserInfo}
              onChange={handleUpdateRequireUserInfo}
            />
          </Flex>
          <NotchedButton
            onClick={() => {
              navigate(`/bao/${campaign._id}`);
            }}
            borderColor="primary"
            color="primary"
          >
            Chi tiết
          </NotchedButton>
        </Flex>
      </Flex>
    </Box>
  );
};

export default CampaignItem;
